<template>
  <div style="padding: 30px">
    <div v-if="is_loading" class="text-center" style="padding: 80px 0px">
      <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
    </div>

    <div v-else>
      <v-container>
        <v-row>
          <v-col cols="6" sm="4">
            <v-checkbox
              class="mt-0"
              v-model="configurations.enable_etsy_order_rename"
              label="Set Shopify order ID to Etsy order ID"
              color="primary"
              hide-details
              value="1"
              readonly
            ></v-checkbox>
          </v-col>

          <v-col cols="6" sm="4">
            <v-checkbox
              class="mt-0"
              v-model="configurations.enable_receipt_id_in_order_note"
              value="1"
              label="Show Etsy order ID on Shopify order note"
              color="primary"
              hide-details
              readonly
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4">
            <h4># Prefix for the Shopify order ID:</h4>
            <p>--> {{ configurations.shopify_order_rename_prefix }}</p>
          </v-col>
          <v-col cols="12" sm="4">
            <h4># Prefix for the Etsy order id in Shopify order note</h4>
            <p>--> {{ configurations.shopify_order_note_prefix }}</p>
          </v-col>
        </v-row>

        <v-divider class="mb-3"></v-divider>
        <v-row>
          <v-col cols="12">
            <h3>Etsy Tag Configuration</h3>
            <v-checkbox
              class="mt-2"
              v-model="configurations.enable_receipt_id_in_order_note"
              value="1"
              label="Enable Etsy tags in Shopify orders"
              color="primary"
              hide-details
              readonly
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-divider class="mb-3 mt-3"></v-divider>
        <v-row>
          <v-col cols="12">
            <h3>Sync Tax/VAT from Etsy Order to Shopify</h3>
            <v-checkbox
              class="mt-2"
              v-model="configurations.sync_tax_on_etsy_orders"
              value="1"
              label="Sync TAX from Etsy order"
              color="primary"
              hide-details
              readonly
            ></v-checkbox>
            <v-checkbox
              class="mt-2"
              v-model="configurations.sync_vat_on_etsy_orders"
              value="1"
              label="Sync VAT from Etsy order"
              color="primary"
              hide-details
              readonly
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-divider class="mb-3 mt-3"></v-divider>
        <v-row>
          <v-col cols="12">
            <h3>Etsy Order Fulfillment Configuration</h3>
            <v-checkbox
              class="mt-2"
              v-model="configurations.fulfill_with_tracking_number"
              value="1"
              label="Only sync order fulfillments to Etsy with tracking numbers"
              color="primary"
              hide-details
              readonly
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import store from '@/store'
export default {
  data() {
    return {
      shop: null,
      is_loading: false,
      configurations: {},
    }
  },
  mounted() {
    this.shop = store.getters.loggedInShop
    this.fetchConfigurations()
  },
  methods: {
    async fetchConfigurations() {
      try {
        this.is_loading = true
        let response = await axios.get(`admin/shops/${this.shop.id}/get-configurations`)
        if (response.data.success) {
          this.configurations = response.data.configurations
        }
      } catch (e) {
      } finally {
        this.is_loading = false
      }
    },
  },
}
</script>