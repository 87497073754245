<template>
  <div style="padding: 30px">
    <v-data-table :headers="headers" :items="shipping_templates" :items-per-page="10" class="elevation-1 mt-5">
      <template v-slot:body="{ items }">
        <tbody v-if="!is_loading">
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.shipping_template_id }}</td>
            <td>{{ item.title }}
              <v-chip small class="ma-2" color="primary" v-if="item.is_calculated == '1'" text-color="white">Calculated</v-chip>
            </td>
            <td>{{ item.country }}</td>
            <td>{{ item.processing_days_display_label }}</td>
            <!-- <td>
              <v-chip small class="ma-2" color="green" text-color="white" v-if="item.app_status == '1'">Active</v-chip>
              <v-chip small class="ma-2" color="red" text-color="white" v-else>Inactive</v-chip>
            </td>
            <td>
              {{ item.last_feed_refresh | date_format('timeago') }}
            </td>
            <td>
              <router-link :to="{ name: 'shops-detail', params: { shop_id: item.id } }">
                <v-btn fab small color="primary darken-1" class="table-action--button">
                  <v-icon small dark>{{ mdiMagnify }}</v-icon>
                </v-btn>
              </router-link>
            </td> -->
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td colspan="6" class="text-center" style="padding: 80px 0px">
              <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import store from '@/store'
export default {
  data() {
    return {
      shop: null,
      is_loading: false,
      shipping_templates: [],
      headers: [
        {text: "ID"},
        {text: "Title"},
        {text: "Country"},
        {text: "Processing Days"},
      ]
    }
  },
  mounted() {
    this.shop = store.getters.loggedInShop
    this.fetshShippingTemplates()
  },
  methods: {
    async fetshShippingTemplates() {
      try {
        this.is_loading = true
        let response = await axios.get(`admin/shops/${this.shop.id}/get-shipping-templates`)
        console.log(response)
        if (response.data.success) {
          this.shipping_templates = response.data.shipping_templates
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.is_loading = false
      }
    },
  },
}
</script>