<template>
  <div style="padding: 30px">
    <div v-if="is_loading" class="text-center" style="padding: 80px 0px">
      <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
    </div>

    <div v-else>
      <v-container>
        <v-row>
          <v-col cols="6" sm="4">
            <h3># Who made it?</h3>
            <p>--> {{ configurations.who_made }}</p>
          </v-col>

          <v-col cols="6" sm="4">
            <h3># When it was made?</h3>
            <p>--> {{ configurations.when_made }}</p>
          </v-col>

          <v-col cols="6" sm="4">
            <h3># Default State?</h3>
            <p>--> {{ configurations.state }}</p>
          </v-col>

          <v-col cols="6" sm="4">
            <h3># What is it?</h3>
            <p>
              -->
              <span v-if="configurations.is_supply == 1">A supply or tool to make things</span>
              <span v-else>A finished product</span>
            </p>
          </v-col>

          <v-col cols="6" sm="4">
            <h3># Listing Type</h3>
            <p>
              -->
              <span v-if="configurations.is_digital == '1'">Digital (A digital file that buyers will download.)</span>
              <span v-else>Physical (A tangible item that you will ship to buyers.)</span>
            </p>
          </v-col>

          <v-col cols="6" sm="4">
            <h3># Renewal Options</h3>
            <p>
              -->
              <span v-if="configurations.should_auto_renew == '1'">Automatic</span>
              <span v-else>Manual</span>
            </p>
          </v-col>

          <v-col cols="6" sm="4">
            <h3># Weight Units</h3>
            <p>
              -->
              <span v-if="configurations.weight_unit == 'oz'">Imperial System (oz, lb) </span>
              <span v-else>Metric System (g, kg)</span>
            </p>
          </v-col>

          <v-col cols="6" sm="4">
            <h3># Dimension Unit</h3>
            <p>
              -->
              <span v-if="configurations.dimension_unit == 'mm'">Metric (eg: mm, cm, m)</span>
              <span v-else>Imperial (eg: in, ft, yd)</span>
            </p>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-4 mb-4">
          <v-col cols="12">
            <h3>Sync Configuration</h3>
          </v-col>

          <v-col cols="12" sm="4">
            <v-checkbox
              class="mt-0"
              v-model="configurations.sync_title"
              label="Overwrite product title"
              color="primary"
              hide-details
              readonly
            ></v-checkbox>
          </v-col>

          <v-col cols="12" sm="4">
            <v-checkbox
              class="mt-0"
              v-model="configurations.sync_description"
              label="Overwrite product description"
              color="primary"
              hide-details
              readonly
            ></v-checkbox>
          </v-col>

          <v-col cols="12" sm="4">
            <v-checkbox
              class="mt-0"
              v-model="configurations.sync_price"
              label="Overwrite product price"
              color="primary"
              hide-details
              readonly
            ></v-checkbox>
          </v-col>

          <v-col cols="12" sm="4">
            <v-checkbox
              class="mt-0"
              v-model="configurations.sync_tags"
              label="Overwrite product tags"
              color="primary"
              hide-details
              readonly
            ></v-checkbox>
          </v-col>

          <v-col cols="12" sm="4">
            <v-checkbox
              class="mt-0"
              v-model="configurations.sync_images"
              label="Overwrite product Images"
              color="primary"
              hide-details
              readonly
            ></v-checkbox>
          </v-col>

          <v-col cols="12" sm="4">
            <v-checkbox
              class="mt-0"
              v-model="configurations.sync_materials"
              label="Overwrite product materials"
              readonly
              color="primary"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="4">
            <v-checkbox
              class="mt-0"
              readonly
              v-model="configurations.sync_shipping_profile_id"
              label="Overwrite Shipping profile"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-4">
          <v-col cols="12">
            <h3>Order & Inventory Sync</h3>
          </v-col>

          <v-col cols="12">
            <v-radio-group v-model="configurations.order_sync_option" row>
              <v-radio readonly label="Enable Both Order and Inventory Sync" value="2"></v-radio>&nbsp; &nbsp;
              &nbsp;&nbsp;&nbsp; <v-radio readonly label="Enable Inventory Sync only" value="1"></v-radio> &nbsp; &nbsp;
              &nbsp;&nbsp;&nbsp;
              <v-radio readonly label="No Order Sync and No Inventory Sync" value="0"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row class="mt-4">
          <v-col cols="12">
            <h3>Order failed notification</h3>
          </v-col>

          <v-col cols="12">
            <v-checkbox
              class="mt-0"
              v-model="configurations.failed_order_email"
              label="Enable Order Failed Notification"
              color="primary"
              hide-details
              readonly
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import store from '@/store'
export default {
  data() {
    return {
      shop: null,
      is_loading: false,
      configurations: {},
    }
  },
  mounted() {
    this.shop = store.getters.loggedInShop
    this.fetchConfigurations()
  },
  methods: {
    async fetchConfigurations() {
      try {
        this.is_loading = true
        let response = await axios.get(`admin/shops/${this.shop.id}/get-configurations`)
        if (response.data.success) {
          this.configurations = response.data.configurations
        }
      } catch (e) {
      } finally {
        this.is_loading = false
      }
    },
  },
}
</script>