<template>
  <div>
    <!-- <v-card style="padding:20px"> -->
    <v-tabs v-model="tab">
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1"> Shipping Templates </v-tab>

      <v-tab href="#tab-2"> Etsy Configuration </v-tab>

      <v-tab href="#tab-3"> Order Configuration </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="1" value="tab-1">
        <shipping-templates></shipping-templates>
      </v-tab-item>

      <v-tab-item :key="2" value="tab-2">
        <etsy-configuration></etsy-configuration>
      </v-tab-item>

      <v-tab-item :key="3" value="tab-3">
        <order-configuration></order-configuration>
      </v-tab-item>
      <!--         
        <v-tab-item>
          <v-card>
            <h1>HELLO</h1>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <h1>HELLO</h1>
          </v-card>
        </v-tab-item> -->
      <!-- <v-tab-item v-for="i in 3" :key="i" :value="'tab-' + i">
          <v-card flat>
            <v-card-text>{{ text }}</v-card-text>
          </v-card>
        </v-tab-item> -->
    </v-tabs-items>
    <!-- </v-card> -->
  </div>
</template>

<script>
import ShippingTemplates from './ShippingTemplates.vue'
import EtsyConfiguration from './EtsyConfiguration.vue'
import OrderConfiguration from './OrderConfiguration.vue'
export default {
  components: {
    ShippingTemplates,
    EtsyConfiguration,
    OrderConfiguration
  },
  data() {
    return {
      tab: null,
      text: 'asdfasf',
    }
  },
}
</script>